import UserService from '@/store/service/service';

const deletePost = async({commit}, post) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/posts`;
  try {
    const { data } = await UserService.deleteRequest(`${api}/${post._id}`);
    commit('GET_POST_BY_ID', null);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default deletePost;