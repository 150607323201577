import UserService from '@/store/service/service';

const getPostById = async ({commit}, postId) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/posts/${postId}`;
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) !== null;
  
  try {
    const { post } = await UserService.getRequest(api, { currentUser });
    
    commit('GET_POST_BY_ID', post);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default getPostById;