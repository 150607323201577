import UserService from '@/store/service/service';

const createMessage = async ({commit}, message) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/messages`;
  try {
    const { data } = await UserService.postRequest(api, message);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
}

export default createMessage;