const currentUser = JSON.parse(window.localStorage.getItem('currentUser'));
const token = JSON.parse(window.localStorage.getItem('token'));
const messages = JSON.parse(window.localStorage.getItem('messages'));

const state = () => ({
  currentUser: currentUser || null,
  token: token || null,

  themeMode: true,
  themeSelected: 'light',
  containerWidth: 1280,
  wrapperWidth: 1920,
  websiteName: 'CsabaTimár.com',
  siteLogo: '',
  pageLoader: true,
  parallaxVideoHeightValue: 0,
  getTheViewPortWidth: 0,
  sidebarPos: 0,
  sidebarPosPadding: 250,
  Zindex: 1000,
  cookieBannerVisible: true,
  showModal: false,
  themeModeChangeOverlay: false,

  messages: messages || [],
  photos: [],
  photo: null,
  posts: [],
  post: null,
  apiMessage: null,
});

export default state;
