import router from '@/router';

const mutations = {
  GET_ALL_MESSAGES(state, payload) {
    window.localStorage.setItem('messages', JSON.stringify(payload));
    state.messages = payload;
  },
  GET_ALL_POSTS(state, payload) {
    state.posts = payload;
  },
  GET_POST_BY_ID(state, payload) {
    state.post = null;
    state.post = payload;
  },
  GET_ALL_PHOTOS(state, payload) {
    state.photos = payload.map((u, i) => ({ ...u, image_id: i + 1, active: false }));
  },
  GET_PHOTO_BY_ID(state, payload) {
    state.photo = null;
    state.photo = payload;
    state.photos = state.photos.map((item) => {
      return { ...item, active: item._id === state.photo._id }
    })
  },
  LOGIN(state, payload) {
    const success = payload.message.includes('Success!');
    if(success) {
      window.localStorage.setItem('currentUser', JSON.stringify(payload.user));
      window.localStorage.setItem('token', JSON.stringify(payload.token));
    }
    state.currentUser = success ? payload.user : null;
    state.token = success ? payload.token : null;

    router.push({name: 'Profile'});
  },

  UPDATE_PROFILE(state, payload) {
    const success = payload.message.includes('Success!');
    if(success) {
      window.localStorage.setItem('currentUser', JSON.stringify(payload.user));
    }
    state.currentUser = success ? payload.user : null;
  },

  LOGOUT(state) {
    window.localStorage.removeItem('currentUser');
    window.localStorage.removeItem('token');

    state.currentUser = null;
    state.token = null;

    if(router.currentRoute.name !== 'Home') {
      router.push({name: 'Home'});
    }
  },

  TOGGLE_API_MESSAGE(state, payload) {
    state.apiMessage = payload;
  },
  
  HIDE_LOADER(state) {
    state.pageLoader = false;
  },
  PARALLAX_VIDEO_HEIGHT(state, payload) {
    state.parallaxVideoHeightValue = payload;
  },
  GET_THE_VIEWPORT_WIDTH(state, payload) {
    state.getTheViewPortWidth = payload;
  },
  TOGGLE_SIDEBAR(state, payload) {
    state.sidebarPos = payload ? 250 : 0;
    state.sidebarPosPadding = payload ? 0 : 250;
  },
  TOGGLE_SIDEBAR_MOBILE(state) {
    state.sidebarPos = 250;
  },
  MODAL_TOGGLE(state) {
    state.showModal = !state.showModal;
    const htmlTag = document.getElementsByTagName('body')[0];
    state.Zindex = state.showModal ? 2 : 1000;

    if (state.showModal) {
      htmlTag.classList.add('locked');
    } else {
      htmlTag.classList.remove('locked');
    }
  },
  THEME_MODE_CHANGE_OVERLAY(state) {
    state.themeModeChangeOverlay = true;
  },
  THEME_MODE_CHANGE(state) {
    state.themeModeChangeOverlay = false;
    state.themeMode = !state.themeMode;

    state.themeSelected = !state.themeMode ? 'dark' : 'light';
    state.siteLogo = require(`../assets/img/logo-${!state.themeMode ? 'light' : 'dark'}.svg`);

    window.localStorage.setItem('themeMode', state.themeMode);
    window.localStorage.setItem(
      'themeSelected',
      JSON.stringify(state.themeSelected),
    );
    window.localStorage.setItem('siteLogo', JSON.stringify(state.siteLogo));
  },
  GET_SITE_PREFERENCE(state) {
    const checkLocalStorage = localStorage.getItem('themeMode') === null
    && localStorage.getItem('themeSelected') === null
    && localStorage.getItem('siteLogo') === null;
    
    state.themeMode = checkLocalStorage ? true : JSON.parse(window.localStorage.getItem('themeMode'));
    state.themeSelected = checkLocalStorage ? 'light' : JSON.parse(
      window.localStorage.getItem('themeSelected'),
    );
    state.siteLogo = checkLocalStorage ? require('../assets/img/logo-dark.svg') : JSON.parse(window.localStorage.getItem('siteLogo'));
  },
  CLOSE_COOKIE_BANNER(state) {
    state.cookieBannerVisible = false;
  },  
};

export default mutations;
