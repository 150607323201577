import UserService from '@/store/service/service';

const getAllMessages = async ({commit}) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/messages`;
  
  try {
    const { messages } = await UserService.getRequest(api);
    commit('GET_ALL_MESSAGES', messages);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default getAllMessages;