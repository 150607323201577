import UserService from '@/store/service/service';

const createPhotos = async({commit}, photos) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/photos`;
  try {
    const formData = new FormData();
    photos.forEach(item => {
      const { _id, image, lat, lng, category } = item;
      const photo = { _id, lat, lng, category };
      formData.append('image', image);
      formData.append('photos', JSON.stringify(photo));
    });

    const { data } = await UserService.postRequest(api, formData);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default createPhotos;