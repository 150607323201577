/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from './auth-header';

class UserService {
  async getRequest(api, params) {
    const response = await axios.get(
      api,
      {
        headers: {
          Accept: 'application/json',
        },
        params: {
          ...params,
        }
      },
    );

    try {
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async putRequest(api, data) {
    const response = await axios.put(
      api,
      data,
      {
        headers: authHeader()
      },
    );
    try {
      return response;
    } catch (error) {
      return error;
    }
  }

  async postRequest(api, data) {
    const response = await axios.post(
      api,
      data,
      {
        headers: authHeader()
      },
    );
    try {
      return response;
    } catch (error) {
      return error;
    }
  }

  async deleteRequest(api, data) {
    const response = await axios.delete(
      api,
      {
        data,
        headers: authHeader()
      },
    );
    try {
      return response;
    } catch (error) {
      return error;
    }
  }

  async patchRequest(api, data) {
    const response = await axios.patch(
      api,
      data,
      {
        headers: authHeader()
      },
    );
    try {
      return response;
    } catch (error) {
      return error;
    }
  };
}

export default new UserService();