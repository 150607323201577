export const logout = async({commit}) => {
  try {
    commit('LOGOUT');
    commit('TOGGLE_API_MESSAGE', 'You have been logged out');
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const toggleApiMessage = async({commit}, message) => {
  try {
    commit('TOGGLE_API_MESSAGE', message);
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const hideLoader = async({ commit }) => {
  await commit('HIDE_LOADER');
};

export const parallaxVideoHeightValue = async({ commit }, payload) => {
  await commit('PARALLAX_VIDEO_HEIGHT', payload);
};

export const toggleSidebar = async({ commit }, payload) => {
  await commit('TOGGLE_SIDEBAR', payload);
};

export const toggleSidebarMobile = async({ commit }) => {
  await commit('TOGGLE_SIDEBAR_MOBILE');
};

export const modalToggle = async({ commit }) => {
  await commit('MODAL_TOGGLE');
};

export const themeModeChangeOverlay = async({ commit }) => {
  await commit('THEME_MODE_CHANGE_OVERLAY');
};

export const themeModeChange = async({ commit }) => {
  await commit('THEME_MODE_CHANGE');
};

export const getSitePreference = async({ commit }) => {
  await commit('GET_SITE_PREFERENCE');
};

export const closeCookieBanner = async({ commit }) => {
  await commit('CLOSE_COOKIE_BANNER');
};

export const getTheViewPortWidth = async({ commit }, payload) => {
  await commit('GET_THE_VIEWPORT_WIDTH', payload);
};
