import UserService from '@/store/service/service';

const editMessage = async ({commit}, message) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/messages`;
  try {
    const { data } = await UserService.patchRequest(`${api}/${message._id}`, message);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default editMessage;