export default function authHeader() {
  const token = JSON.parse(window.localStorage.getItem('token'));
  if (token) {
    return {
      'Authorization': `Bearer ${token}`,
      'Accept' : 'application/json',
      'Content-Type': 'application/json'
    };
  }
  return {};
}