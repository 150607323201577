import UserService from '@/store/service/service';

const register = async({commit}, user) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/users`;

  try {
    const formData = new FormData();
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('image', user.image);

    const { data } = await UserService.postRequest(`${api}/signup`, formData);

  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default register;