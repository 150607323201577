import createEditPost from "./posts/createEditPost";
import getAllPosts from "./posts/getAllPosts";
import getPostById from "./posts/getPostById";
import deletePost from "./posts/deletePost";
import createPhotos from "./photos/createPhotos";
import getAllPhotos from "./photos/getAllPhotos";
import getPhotoById from "./photos/getPhotoById";
import deletePhoto from "./photos/deletePhoto";
import editPhoto from "./photos/editPhoto";
import createMessage from "./messages/createMessage";
import getAllMessages from "./messages/getAllMessages";
import deleteMessage from "./messages/deleteMessage";
import editMessage from "./messages/editMessage";
import login from "./login";
import register from "./register";
import updateProfile from "./updateProfile";
import { 
  logout,
  toggleApiMessage,
  hideLoader,
  parallaxVideoHeightValue,
  toggleSidebar,
  toggleSidebarMobile,
  modalToggle,
  themeModeChangeOverlay,
  themeModeChange,
  getSitePreference,
  closeCookieBanner,
  getTheViewPortWidth,
} from "./other";

const actions = {
  createEditPost,
  getAllPosts,
  getPostById,
  deletePost,
  createPhotos,
  getAllPhotos,
  getPhotoById,
  deletePhoto,
  editPhoto,
  createMessage,
  getAllMessages,
  deleteMessage,
  editMessage,
  login,
  register,
  updateProfile,
  logout,
  toggleApiMessage,
  hideLoader,
  parallaxVideoHeightValue,
  toggleSidebar,
  toggleSidebarMobile,
  modalToggle,
  themeModeChangeOverlay,
  themeModeChange,
  getSitePreference,
  closeCookieBanner,
  getTheViewPortWidth,
};

export default actions;