import UserService from '@/store/service/service';

const editPhoto = async ({commit}, photo) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/photos`;
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) !== null;

  try {
    const formData = new FormData();
    
    formData.append('_id', photo._id);
    formData.append('image', photo.image);
    formData.append('lat', photo.lat);
    formData.append('lng', photo.lng);
    formData.append('category', photo.category);
    formData.append('likes', photo.likes);
    formData.append('currentUser', currentUser);

    const { data } = await UserService.patchRequest(`${api}/${photo._id}`, formData);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default editPhoto;