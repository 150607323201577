import Vue from 'vue';
import './registerServiceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import VueMeta from 'vue-meta';
import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyload from 'vue-lazyload';
import VueParticles from 'vue-particles';
import VueCodeHighlight from 'vue-code-highlight';
import '../node_modules/vue-code-highlight/themes/prism-okaidia.css';

import store from './store';
import router from './router';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './assets/scss/main.scss';

import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(Vue2TouchEvents);
Vue.use(VueLazyload, {
  preLoad: 1,
  attempt: 1,
});
Vue.use(VueParticles);
Vue.use(VueCodeHighlight);

/* Vue.use(VueAnalytics, {
  id: 'UA-110155748-1',
  router
}); */

new Vue({
  mounted() {
    AOS.init({
      once: true,
    })
  },
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
