<template>
  <div id="app">
    <div id="wrapper" :class="themeSelected">
      <Loader/>

      <transition name="fade">
        <div v-if="themeModeChangeOverlay" :style="`max-width: ${wrapperWidth}px`" class="theme-change-overlay">
          <CogAnimation />
        </div>
      </transition>

      <div class="container-fluid wrapper-container"
        v-if="!pageLoader"
        :style="`padding-bottom: 70px; max-width: ${wrapperWidth}px`"
      >
        <ProgressIndicator :key="$route.name"/>
        
        <Sidebar />

        <main :style="`padding-right: ${sidebarPosPadding}px;`">
          <router-view/>
        </main>

        <ScrollToTop />

        <CookieBanner/>

        <Music />

        <NewContentAvailable v-if="isProduction"/>

        <ApiMessage />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '@/components/Loader.vue';

const CogAnimation = () => import('@/components/CogAnimation');
const Sidebar = () => import('@/components/Sidebar');
const ScrollToTop = () => import('@/components/ScrollToTop');
const CookieBanner = () => import('@/components/CookieBanner');
const Music = () => import('@/components/Music');
const NewContentAvailable = () => import('@/components/NewContentAvailable');
const ProgressIndicator = () => import('@/components/ProgressIndicator');
const ApiMessage = () => import('@/components/ApiMessage');

export default {
  components: {
    Loader,
    CogAnimation,
    Sidebar,
    ScrollToTop,
    CookieBanner,
    Music,
    NewContentAvailable,
    ProgressIndicator,
    ApiMessage,
  },
  data: () => ({
  }),
  computed: {
    ...mapState([
      'pageLoader',
      'sidebarPosPadding',
      'themeSelected',
      'wrapperWidth',
      'themeModeChangeOverlay',
      'getTheViewPortWidth',
      'token',
      'currentUser',
    ]),
    isProduction() {
      return process.env.NODE_ENV !== 'development';
    },
  },
  beforeUpdate() {
    window.addEventListener('resize', () => this.getWindowWidth());
    this.expiredToken();
  },
  mounted() {
    this.getWindowWidth();
    this.getSitePreference();
  },
  methods: {
    getWindowWidth() {
      this.$store.dispatch('getTheViewPortWidth', document.documentElement.clientWidth);
    },
    getSitePreference() {
      this.$store.dispatch('getSitePreference');
      setTimeout(() => {
        this.$store.dispatch('hideLoader');
      }, 700);
    },
    expiredToken() {
      const parseJwt = (token) => {
        const base64Payload = token ? token.split('.')[1] : [];
        const payload = Buffer.from(base64Payload, 'base64');
        return token ? JSON.parse(payload.toString()) : '';
      };
      const token = JSON.parse(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : '';
      const jwToken = this.token ? this.token : '';
      if (Date.now() >= parseJwt(token).exp * 1000 || token !== jwToken) {
        this.$store.dispatch('logout');
        this.$router.push('/').catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

#wrapper {
  .theme-change-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.75);
  }

  &.dark {
    .wrapper-container {
      background-color: $color1;
      color: $color3;
    }
  }

  &.light {
    .wrapper-container {
      background-color: $color9;
      color: $color1;
    }
  }

  .wrapper-container {
    position: relative;
    overflow: hidden;
    min-height: 100%;
    margin: auto;

    main {
      transition: all 0.3s ease;
      z-index: 3;
      position: relative;

      @media (max-width: $size-desktop) {
        padding-right: 0 !important;
      }
    }
  }
}
</style>
