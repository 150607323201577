import Vue from 'vue';
import VueRouter from 'vue-router';

const Home = () => import('@/views/Home');
const NotFound = () => import('@/views/404');
const CookiePolicy = () => import('@/views/CookiePolicy');
const Blog = () => import('@/views/Blog');
const BlogPost = () => import('@/views/Blog/_id');
const Photography = () => import('@/views/Photography');
const PhotographyPhoto = () => import('@/views/Photography/_id');

const Profile = () => import('@/views/Admin/Profile');
const Post = () => import('@/views/Admin/Post');
const Photo = () => import('@/views/Admin/Photo');
const Authentication = () => import('@/views/Admin/Authentication');
const Message = () => import('@/views/Admin/Message');

const conditionalRoutes = (next, to) => {
  const user = JSON.parse(localStorage.getItem('currentUser'));
  if (user !== null) {
    next();
  } else {
    next({
      path: '/',
      params: { nextUrl: to.fullPath },
    });
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/blog/:id',
    name: 'BlogPost',
    component: BlogPost,
  },
  {
    path: '/photography',
    name: 'Photography',
    component: Photography,
  },
  {
    path: '/photography/:id',
    name: 'PhotographyPhoto',
    component: PhotographyPhoto,
  },
  {
    path: '/admin/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      conditionalRoutes(next, to);
    },
  },
  {
    path: '/admin/post',
    name: 'Post',
    component: Post,
    beforeEnter: (to, from, next) => {
      conditionalRoutes(next, to);
    },
  },
  {
    path: '/admin/photo',
    name: 'Photo',
    component: Photo,
    beforeEnter: (to, from, next) => {
      conditionalRoutes(next, to);
    },
  },
  {
    path: '/admin/message',
    name: 'Message',
    component: Message,
    beforeEnter: (to, from, next) => {
      conditionalRoutes(next, to);
    },
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: Authentication,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 100);
    });
  },
});

export default router;
