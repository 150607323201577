import UserService from '@/store/service/service';

const getPhotoById = async ({commit}, item) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/photos/${item._id}`;
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) !== null;
  
  try {
    const { photo } = await UserService.getRequest(api, { currentUser });
    photo.image_id = item.image_id;
    
    commit('GET_PHOTO_BY_ID', photo);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default getPhotoById;