<template>
  <div>
    <div
      v-if="pageLoader"
      :class="`page-loader ${themeSelected}`">
      <img
        :src="siteLogo"
        :alt="websiteName"
        data-aos="zoom-in"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['websiteName', 'themeSelected', 'siteLogo', 'pageLoader']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dark {
    background: $color1;
  }

  &.light {
    background: $color9;
  }
}
</style>
