import UserService from '@/store/service/service';

const deleteMessage = async({commit}, message) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/messages`;
  try {
    const { data } = await UserService.deleteRequest(`${api}/${message._id}`);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default deleteMessage;