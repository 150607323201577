import UserService from '@/store/service/service';

const updateProfile = async({commit}, user) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/users`;

  try {
    const formData = new FormData();
    formData.append('_id', user._id);
    formData.append('name', user.name);
    formData.append('password', user.password);
    formData.append('image', user.image);

    const { data } = await UserService.patchRequest(`${api}/updateProfile`, formData);
    commit('TOGGLE_API_MESSAGE', data.message);
    commit('UPDATE_PROFILE', data);

  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default updateProfile;