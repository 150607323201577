import UserService from '@/store/service/service';

const login = async({commit}, user) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/users`;

  try {
    const { data } = await UserService.postRequest(`${api}/login`, user);
    commit('LOGIN', data);
    commit('TOGGLE_API_MESSAGE', data.message);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default login;