import UserService from '@/store/service/service';

const getAllPhotos = async({commit}) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/photos`;
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) !== null;

  try {
    const { photos } = await UserService.getRequest(api, { currentUser });
    commit('GET_ALL_PHOTOS', photos);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
};

export default getAllPhotos;