import UserService from '@/store/service/service';

const createEditPost = async ({commit}, post) => {
  const api = `${process.env.VUE_APP_BACKEND_URL}api/posts`;
  try {
    const formData = new FormData();
    formData.append('_id', post._id);
    formData.append('title', post.title);
    formData.append('bannerUrl', post.bannerUrl);
    formData.append('status', post.status);

    post.category.forEach(item => {
      formData.append('category', item);
    });

    post.contents.forEach(item => {
      formData.append('contents', JSON.stringify(item));
    });

    let data;

    if(post._id) {
      // edit post
      data = await UserService.patchRequest(`${api}/${post._id}`, formData);
      commit('TOGGLE_API_MESSAGE', data.data.message);
    } else {
      // create post
      data = await UserService.postRequest(api, formData);
      commit('TOGGLE_API_MESSAGE', data.data.message);
    }

    commit('GET_POST_BY_ID', null);
  } catch (error) {
    commit('TOGGLE_API_MESSAGE', error);
    throw new Error(`${error}`);
  }
}

export default createEditPost;